/* eslint-disable no-plusplus */
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Router from 'next/router';
// import { useTranslation } from '@i18n';
import Keyboard from 'react-simple-keyboard';
import dynamic from 'next/dynamic';
import isUseVirtualKeyboard from '@root/src/helpers/virtualKeyboard';
import useStyles from './style';

const SearchSuggestion = dynamic(() => import('../SearchSuggestion'), { ssr: false });

export default function ComboBox(props) {
    const {
        handleSearch, setValue, OptionsItem, width = '100%', maxHeight = '80vh', disabled, value = '',
    } = props;
    // const { t } = useTranslation(['home']);
    const styles = useStyles();
    const keyboard = React.useRef();
    const [item] = React.useState([]);
    const [showKeyboard, setShowKeyboard] = React.useState(false);
    const [showRecomendation, setShowRecomendation] = React.useState(false);
    const [layout, setLayout] = React.useState('default');

    const startAutocomplete = (e) => {
        setValue(e.target.value);
        if (isUseVirtualKeyboard()) {
            keyboard.current.setInput(e.target.value);
        }
    };

    const handleKeyPress = (e) => {
        handleSearch(e);
    };

    const handleShift = () => {
        const newLayoutName = layout === 'default' ? 'shift' : 'default';
        setLayout(newLayoutName);
    };

    const onKeyPress = (button) => {
        if (button === '{shift}' || button === '{lock}') {
            handleShift();
        } else if (button === '{enter}') {
            handleSearch({}, true);
        }
    };

    const onChangeInput = (event) => {
        const input = event;
        setValue(input);
        keyboard.current.setInput(input);
    };

    React.useEffect(() => {
        function clickHanlder(e) {
            if (
                !(e.target.nodeName === 'INPUT')
                && (
                    !e.target.classList.contains('hg-button')
                    && !e.target.classList.contains('hg-rows')
                    && !e.target.classList.contains('hg-row')
                )
            ) {
                setShowKeyboard(false);
                setShowRecomendation(false);
            }
        }
        window.addEventListener('click', clickHanlder);
        return window.removeEventListener('click', clickHanlder, true);
    }, []);

    return (
        <div className={styles.searchContainer}>
            <Autocomplete
                disabled={disabled}
                id="combo-box-demo"
                classes={{
                    root: styles.root,
                    inputRoot: styles.searchInputRoot,
                }}
                options={item}
                getOptionLabel={(option) => option.name}
                forcePopupIcon
                style={{ width }}
                openOnFocus={false}
                onFocus={() => {
                    if (isUseVirtualKeyboard()) {
                        setShowKeyboard(true);
                    }
                    setShowRecomendation(true);
                }}
                open={false}
                ListboxProps={{ style: { maxHeight, height: 'auto' } }}
                renderOption={(option) => <OptionsItem {...option} />}
                renderInput={(params) => (
                    <TextField
                        id="standard-basic"
                        placeholder="Cari Produk"
                        margin="normal"
                        onBlur={() => {}}
                        {...params}
                        variant="outlined"
                        size="small"
                        inputProps={{
                            ...params.inputProps,
                            value,
                        }}
                    />
                )}
                clearOnBlur={false}
                onInputChange={(e) => {
                    startAutocomplete(e);
                    if (isUseVirtualKeyboard()) {
                        onChangeInput(e.target.value);
                    }
                }}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={() => {
                    Router.push(
                        '/[...slug]',
                        `/${value.url_key}`,
                    );
                }}
            />
            {
                showRecomendation ? (
                    <SearchSuggestion />
                ) : null
            }
            {
                showKeyboard && (
                    <div
                        style={{
                            position: 'fixed',
                            width: '100%',
                            bottom: 0,
                            right: 0,
                            zIndex: 999999,
                        }}
                    >
                        <Keyboard
                            keyboardRef={(r) => { keyboard.current = r; }}
                            layoutName={layout}
                            onChange={onChangeInput}
                            onKeyPress={onKeyPress}
                        />
                    </div>
                )
            }
        </div>
    );
}
